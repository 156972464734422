import { SHOPID, SHOPSTAFFID } from '@/constants'
import { service } from '@/utils/request'
import { getSession } from '@/utils/session'

export default {
  getLevelData () {
    return service.get('/sharer/level/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  saveLevelData (payload) {
    return service.post('/sharer/level/set', payload)
  },
  getPartnerTreeData (payload) {
    return service.get('/partner/list', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  deletePartner (payload) {
    return service.postForm('/partner/delete', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  savePartner (payload) {
    return service.post('/partner/info/save', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getPartnerDetail (payload) {
    return service.get('/partner/info/get', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getPromoteProductPage (payload) {
    return service.get('/promote/product/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID),
        shopStaffId: getSession(SHOPSTAFFID)
      }
    })
  },
  deletePromoteProduct (payload) {
    return service.postForm('/promote/product/delete', payload)
  },
  addPromoteProduct (payload) {
    return service.post('/promote/product/add', payload)
  },
  getChooseProducts (payload) {
    return service.get('/promote/product/non/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID),
        shopStaffId: getSession(SHOPSTAFFID)
      }
    })
  },
  getPromoteLevelList () {
    return service.get('/promote/product/sharer/level/list', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  setAllBrokerage (payload) {
    return service.post('/promote/product/brokerage/rate/batch/save', payload)
  },
  getPromoteRateInfo (payload) {
    return service.get('/promote/product/brokerage/rate/info', {
      params: payload
    })
  },
  setSingleBrokerage (payload) {
    return service.post('/promote/product/brokerage/rate/save', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  getPartnerQrcode (payload) {
    return service.postForm('/partner/createQrCode', payload)
  },
  getSharerList (payload) {
    return service.get('/user/sharer/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getGoodsOrder (payload) {
    return service.get('/promote/order/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  exportSharerList (params) {
    return service.get('/user/sharer/export', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  exportOrderList (params) {
    return service.get('/promote/order/export', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  getRecordList (payload) {
    return service.get('/user/sharer/bill/page', {
      params: payload
    })
  },
  getWithdrawList (payload) {
    return service.get('/sharer/withdraw/apply/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  passWithdraw (payload) {
    return service.postForm('/sharer/withdraw/apply/pass', payload)
  },
  refuseWithdraw (payload) {
    return service.postForm('/sharer/withdraw/apply/refuse', payload)
  },
  passSharer (payload) {
    return service.postForm('/user/sharer/pass', payload)
  },
  refuseSharer (payload) {
    return service.postForm('/user/sharer/refused', payload)
  },
  getVirtualSharerId (payload) {
    return service.get('/partner/virtual/sharer/get', {
      params: payload
    })
  },
  unbindSharer (payload) {
    return service.postForm('/user/sharer/untie', payload)
  },
  exportWithdrawInfo (params) {
    return service.get('/sharer/withdraw/apply/export', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
  exportCardPic (params) {
    return service.get('/sharer/withdraw/apply/exportCardPic', {
      params: {
        ...params,
        shopId: getSession(SHOPID)
      },
      responseType: 'blob'
    })
  },
}
