<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="query" layout="inline" :model="query" @submit.native.prevent @submit="handleQuery">
        <a-form-model-item prop="userName" label="微信昵称">
          <a-input allow-clear v-model="query.userName" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="userNo" label="用户编号">
          <a-input allow-clear v-model="query.userNo" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="userPhone" label="手机号">
          <a-input allow-clear v-model="query.userPhone" placeholder="请输入"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleQuery">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>

    <base-table
      bordered
      :columns="columns"
      :dataSource="data"
      :pagination="page"
      :rowKey="x => x.userAccountId"
      :loading="loading"
      @change="handleTableChange"
    >
      <template #user="ctx">
        <div class="user-card">
          <img v-if="ctx.headImg" :src="ctx.headImg" alt="" />
          <p>{{ctx.userName}}</p>
        </div>
      </template>
      <template #operation="ctx">
        <a-button v-pms="'charge'" type="link" @click="chargeMoneyFor(ctx)">充值</a-button>
        <a-button v-pms="'charge-voucher'" type="link" @click="chargeVoucherFor(ctx)">充值卡</a-button>
      </template>
    </base-table>

    <a-modal
      v-model="visible"
      :footer="null"
      :keyboard="false"
      :mask-closable="false"
      width="70vw"
    >
      <charge-form
        :key="key"
        :user="user"
        :type="chargeType"
        @close="visible = false"
        @success="getTableData"
      />
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from '@vue/composition-api'
import { getSession } from '@/utils/session'
import { SHOPID } from '@/constants'
import { capital } from '@/api'
import ChargeForm from './ChargeForm'

export default {
  name: 'PageCharge',
  components: {
    ChargeForm,
  },
  setup () {
    const query = reactive({
      userName: '',
      userPhone: '',
      shopId: getSession(SHOPID),
    })

    const page = reactive({
      current: 1,
      size: 10,
      total: 0
    })

    const data = ref([])
    const loading = ref(false)
    async function getTableData () {
      loading.value = true
      const res = await capital.getMemberPage({
        ...query,
        ...page
      })
      loading.value = false
      if (res.code === '00000') {
        page.total = res.page.total
        data.value = res.data
      }
    }
    onMounted(() => {
      getTableData()
    })

    function handleQuery () {
      page.current = 1
      getTableData()
    }

    function handleReset () {
      query.userName = ''
      query.userPhone = ''
      query.userNo = ''
      handleQuery()
    }

    function handleTableChange ({ current }) {
      page.current = current
      getTableData()
    }

    const columns = [
      {
        title: '用户',
        scopedSlots: {
          customRender: 'user'
        }
      },
      {
        title: '用户编号',
        dataIndex: 'userNo'
      },
      {
        title: '手机号',
        dataIndex: 'userPhone'
      },
      {
        title: '余额',
        dataIndex: 'amount'
      },
      {
        title: '备注',
        dataIndex: 'remark'
      },
      {
        title: '时间',
        dataIndex: 'createTime'
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'operation'
        }
      }
    ]

    const visible = ref(false)
    const key = ref(0)
    const user = ref(null)
    const chargeType = ref('') // money voucher
    watch(visible, value => {
      if (!value) key.value++
    })
    function chargeMoneyFor (data) {
      visible.value = true
      user.value = data
      chargeType.value = 'money'
    }

    function chargeVoucherFor (data) {
      visible.value = true
      user.value = data
      chargeType.value = 'voucher'
    }

    return {
      query,
      data,
      page,
      loading,
      handleQuery,
      handleReset,
      columns,
      handleTableChange,
      key,
      visible,
      chargeMoneyFor,
      chargeVoucherFor,
      chargeType,
      user,
      getTableData,
    }
  }
}
</script>
